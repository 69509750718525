import React, { useEffect, useState } from "react";
import { Link, graphql, navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PageLayout from "../components/pageLayout";
import {
  Card,
  Grid,
  Typography,
  useMediaQuery,
  Box,
  CardContent,
} from "@mui/material";
import { getWindowSize, genHelmet, readableDate } from "../actions/baseActions";

import theme from "../gatsby-theme-material-ui-top-layout/theme";

const title = "Posts";
const headline = "News and updates from BVA and our friends.";

const aspectRatio = 16 / 9;

const styles = {
  root: {
    paddingTop: "60px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(8),
  },
  tileContainer: {
    width: "100%",
    height: "100%",
    "& > div": {
      width: "100%",
      height: "100%",
      // width: 'min-content',
      // height: 'min-content'
    },
  },
  gatsbyImg: {
    width: "100%",
    height: "100%",
  },
  contentSlideWrapper: {
    opacity: 0,
    transition: "0.25s ease-in-out",
    "&:hover": {
      opacity: 1,
    },
    position: "absolute",
    top: 0,
    height: "100%",
    "& > div": {
      maxWidth: "90%",
      "& > *": {
        color: "#fff",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
      },
    },
    "& button": {
      backgroundColor: "#fff",
      color: "#000",
    },
  },
  contentSlideType: {
    textShadow: "0px 0px 16px #fff, 0px 0px 8px #000",
  },
  postContainerWrapper: {
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      marginLeft: "initial",
      marginRight: "initial",
    },
    "& a": {
      textDecoration: "none",
    },
  },
  card: {
    margin: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
    border: "6px solid black",
    borderRadius: 0,
  },
  excerpt: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
  },
};

const getCreatedAt = (projects) => {
  return "";
};

const getUpdateAt = (projects) => {
  return "";
};

const News = (props) => {
  const { nodes } = props.data.allContentfulPost;
  const [columns, setColumns] = useState(1);
  const [rowHeight, setRowHeight] = useState(0);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: getWindowSize().width,
        height: getWindowSize().height,
      });
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!windowSize.width || !windowSize.height) return;
    const { values } = theme.breakpoints;
    if (windowSize.width >= values.lg) {
      setColumns(4);
      setRowHeight(windowSize.width / 4 / aspectRatio);
    } else if (windowSize.width >= values.sm) {
      setColumns(2);
      setRowHeight(windowSize.width / 2 / aspectRatio);
    } else {
      setColumns(1);
      setRowHeight(windowSize.width / 1 / aspectRatio);
    }
  }, [windowSize]);

  const posts = nodes
    .filter((a) => !a.unlisted)
    .sort((a, b) => (a.publishedDate > b.publishedDate ? -1 : 1));

  const createdAt = getCreatedAt(posts);

  return (
    <React.Fragment>
      {genHelmet(
        {
          title,
          headline,
          coverImage: posts[0].coverImage,
          createdAt,
          updatedAt: createdAt,
        },
        {
          type: "Posts",
          siteData: props.data.contentfulPage,
          path: props.path,
        }
      )}
      <PageLayout location={props.location}>
        <Grid direction="column" container sx={styles.root}>
          <Grid item sx={styles.title}>
            {/*<Typography variant="h3">{title}</Typography>*/}
            <Typography variant={smDown ? "h6" : "h5"}>{headline}</Typography>
          </Grid>
          <Box sx={styles.postContainerWrapper}>
            <Grid container>
              {posts.map((post, index) => (
                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  key={Math.random().toString()}
                  justifyContent={index % 2 === 0 ? "flex-start" : "flex-end"}
                >
                  <Grid item xs={12} md={8}>
                    <Link to={`/posts/${post.slug}`}>
                      <Card variant="outlined" sx={styles.card}>
                        <GatsbyImage
                          image={getImage(post.coverImage.gatsbyImageData)}
                          title={post.coverImage.title}
                          alt={post.coverImage.description}
                          style={styles.gatsbyImg}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            {post.title}
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="button"
                            component="div"
                          >
                            {readableDate(
                              post.publishedDate
                                ? post.publishedDate
                                : post.createdAt
                            )}
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="body2"
                            component="div"
                            sx={styles.excerpt}
                          >
                            {post?.markdown?.childMarkdownRemark?.excerpt ||
                              post.headline}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Link>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </PageLayout>
    </React.Fragment>
  );
};

export default News;

export const pageQuery = graphql`
  {
    allContentfulPost {
      nodes {
        headline
        slug
        title
        createdAt
        publishedDate
        unlisted
        coverImage {
          title
          description
          gatsbyImageData
          banner: gatsbyImageData(width: 1200, formats: JPG, sizes: "1200px")
          thumbnail: gatsbyImageData(width: 600, formats: JPG, sizes: "600px")
        }
        markdown {
          childMarkdownRemark {
            excerpt
          }
        }
      }
    }
    contentfulPage(slug: { eq: "/" }) {
      slug
      headline
      title
      coverImage {
        title
        description
        gatsbyImageData
        banner: gatsbyImageData(width: 1200, formats: JPG, sizes: "1200px")
        thumbnail: gatsbyImageData(width: 600, formats: JPG, sizes: "600px")
      }
    }
  }
`;
